import {
  isEmpty,
  isNaN,
  isNumber,
  last,
  lowerCase,
  mapValues,
  padStart,
} from "lodash";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import currency from "currency.js";
import { toast } from "react-toastify";
import { customAlphabet } from "nanoid";
import moment, { isMoment } from "moment";
import { addDays, addMonths, addWeeks, compareAsc, endOfMonth, format, startOfYear } from "date-fns";
import CryptoJS from "crypto-js";
import { appSettings } from "../config";
import { cloneDeep } from "lodash";
import { isArray } from "lodash";
import { startOfMonth } from "date-fns";

export const VendorCat = [
  { label: "Select", value: "" },
  { label: "Service", value: "Service" },
  { label: "Goods", value: "Goods" },
];

export const VendorPayment = [
  { label: "Select", value: "" },
  { label: "Post Payment", value: "Post_Payment" },
  { label: "Pre Payment", value: "Pre_Payment" },
  { label: "Part Payment", value: "Part_Payment" },
];

export function activeRowStyle() {
  return {
    backgroundColor: "#2463AE",
    color: "#fff",
  };
}

export function waitFor(time) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

export const paginationOptions = {
  previousLabel: <ChevronLeftIcon />,
  nextLabel: <ChevronRightIcon />,
  pageClassName: "page-item",
  pageLinkClassName: "page-link",
  previousClassName: "page-item",
  previousLinkClassName: "page-link",
  nextClassName: "page-item next",
  nextLinkClassName: "page-link",
  breakLabel: "...",
  breakClassName: "page-item border-none",
  breakLinkClassName: "page-link",
  containerClassName: "pagination",
  activeClassName: "active",
  hrefBuilder: (pageIndex) => {
    let url = new URL(document.location.href);
    let params = new URLSearchParams(url.search);

    params.set("page", pageIndex);
    url.search = params.toString();

    return url.toString();
  },
};

export function searchParamsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    if (key === "switchCompany") continue;
    // if (!value) continue;
    result[key] = value;
  }

  return result;
}

export function isInventoryOrFundRequest(requisitionType) {
  return [
    "inventory",
    "fund request",
    "material request",
    "budget",
    "rfq",
    "po",
    "retirement for fund request",
  ].includes(lowerCase(requisitionType));
}

export function currencyJs(args) {
  return currency(...args);
}

export function resolveApprovalBadgeBg(status) {
  return ["pending", "pending payment", "pending reimbursement"].includes(
    lowerCase(status)
  )
    ? "warning"
    : ["disapproved", "pending retirement"].includes(lowerCase(status))
      ? "danger"
      : "success";
}

export const maxTopPopperConfig = ({ maxTop = -210 }) => {
  return {
    name: "maxTopPopperConfig",
    enabled: true,
    phase: "beforeWrite",
    fn({ state }) {
      const oldTransfrom = String(state.styles.popper?.transform);
      const y = Number(
        String(oldTransfrom)
          .split(",")[1]
          .split(")")[0]
          .split("px")[0]
          .trim()
      );

      const x = Number(
        String(oldTransfrom)
          .split(",")[0]
          .split("(")[1]
          .split("px")[0]
          .trim()
      );

      state.styles.popper.transform =
        y < maxTop
          ? `translate(${x}px, ${maxTop}px)`
          : `translate(${x}px, ${y}px)`;
    },
  };
};

export function customerFullName(customer) {
  return `${customer?.LastName || ""}`.trim();
  /*  return `${customer?.LastName || ""} ${customer?.FirstName || ""} ${
    customer?.MiddleName || ""
  }`.trim(); */
}

export function employeeFullName(employee) {
  return `${employee?.LastName || ""} ${employee?.FirstName ||
    ""}  ${employee?.MiddleName || ""}`;
}

export function defaultSelectValue(
  value,
  options = [],
  defaultOption = { value: "", label: "All" }
) {
  return value && options
    ? options.find((el) => el.value === value)
    : defaultOption;
}

export function copyText(text, successText = "Copied") {
  const textarea = document.createElement("textarea");
  textarea.textContent = text;
  textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
  document.body.appendChild(textarea);
  textarea.select();
  textarea.setSelectionRange(0, 99999);
  document.execCommand("copy");
  document.body.removeChild(textarea);
  toast.success(successText);
}

export const styleHelper = {
  innerHeight: window.innerHeight,
  innerWidth: window.innerWidth,
  isMobile: window.matchMedia("(max-width: 960px )").matches,
};

export const reactSelectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    //  primary25: "#E2E8F0",
    primary: "#2463AE",
  },
});

export const qtyFormatToString = (
  qty,
  roundPieces = false,
  convertableUOM = "ton"
) => {
  // console.log(qty);
  const value = String(qty);
  const splitValue = value.split("-");

  const plural = (value) => {
    return Number(value) > 1 ? "s" : "";
  };

  if (splitValue?.length > 1) {
    const tons = splitValue[0];
    let pieces = splitValue[1];
    const splitType = splitValue[2];

    if (roundPieces && pieces) {
      pieces = parseInt(pieces);
    }

    const tonsText =
      Number(tons) > 0
        ? `${currency(tons, {
          symbol: "",
          //  precision: Number.isInteger(Number(tons)) ? 0 : 1,
          precision: 2,
          format: formatForQty,
        }).format()} ${convertableUOM}${plural(tons)}`
        : "";
    const piecesText =
      Number(pieces) > 0
        ? `${currency(pieces, {
          symbol: "",
          // precision: Number.isInteger(Number(pieces)) ? 0 : 1,
          precision: 2,
          format: formatForQty,
        }).format()} ${splitType === "normal" ? "" : "pieces"}`
        : `${splitType === "normal" ? "0" : ""}`;

    return `${tonsText} ${piecesText}`;
  } else {
    const tons = splitValue[0];
    return `${currency(tons, {
      symbol: "",
      //  precision: Number.isInteger(Number(tons)) ? 0 : 1,
      precision: 2,
      format: formatForQty,
    }).format()} ${convertableUOM}${plural(tons)}`;
  }
};

// qty to tons and pcs eg 1.5 1 ton 5 pcs
export const qtyFormat = (qty, measurement, itemMeasurements = []) => {
  try {
    // console.log(qty, isEmpty(measurement));
    if (isEmpty(measurement)) return `0-${qty}-normal`;

    const foundMeasurement = itemMeasurements.find(
      (el) => el.Size === measurement
    );

    if (isEmpty(foundMeasurement)) return `0-${qty}-normal`;

    let base = foundMeasurement?.Quantity ? foundMeasurement.Quantity : 13;

    /* switch (measurement) {
      case "8 mm":
        base = 210;
        break;
      case "10 mm":
        base = 135;
        break;
      case "12 mm":
        base = 94;
        break;
      case "16 mm":
        base = 53;
        break;
      case "20 mm":
        base = 34;
        break;
      case "25 mm":
        base = 22;
        break;
      case "32 mm":
        base = 13;
        break;
      default:
        base = 13;
      // code block
    } */

    const value = currency(qty, { symbol: "", separator: "" })
      .divide(base)
      .format();

    // console.log(value);

    const tons = Math.trunc(Number(value));
    const pcs = qty % base;
    //  console.log(qty, base, tons, pcs);
    return `${tons}-${pcs}`;
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const tonsToPcs = (tons, measurement, itemMeasurements = []) => {
  try {
    const foundMeasurement = itemMeasurements.find(
      (el) => el.Size === measurement
    );

    let base = foundMeasurement?.Quantity;
    if (isEmpty(foundMeasurement)) base = 1;

    /* switch (measurement) {
      case "8 mm":
        base = 210;
        break;
      case "10 mm":
        base = 135;
        break;
      case "12 mm":
        base = 94;
        break;
      case "16 mm":
        base = 53;
        break;
      case "20 mm":
        base = 34;
        break;
      case "25 mm":
        base = 22;
        break;
      case "32 mm":
        base = 13;
        break;
      default:
      // code block
    } */

    const value = currency(tons, { symbol: "", separator: "" })
      .multiply(base)
      .format();

    return Number(value);
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const pcsToTons = (pcs, measurement, itemMeasurements = []) => {
  try {
    const foundMeasurement = itemMeasurements.find(
      (el) => el.Size === measurement
    );
    let base = foundMeasurement.Quantity;
    if (isEmpty(foundMeasurement)) base = 1;

    const value = currency(pcs, { symbol: "", separator: "" })
      .divide(base)
      .format();

    // return parseInt(Number(value));
    return Number(value);
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const toTonsOrPcs = (qty, measurement, itemMeasurements = []) => {
  const foundMeasurement = itemMeasurements.find(
    (el) => el.Size === measurement
  );
  let base = foundMeasurement?.Quantity ? foundMeasurement.Quantity : 13;

  const value = currency(qty, { symbol: "", separator: "" })
    .divide(base)
    .format();

  const tons = Math.trunc(Number(value));
  const pcs = qty % base;
  //  console.log(qty, base, tons, pcs);
  return Number(tons) > 0 ? tons : pcs;
};

const alphabet =
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const number = "0123456789";

export const nanoid = (max = 12, type = "alphabet") => {
  const nanoid = customAlphabet(type === "alphabet" ? alphabet : number, max);
  const str = nanoid();
  return str;
};

// export const nanoidNumber = () => {}

export function convertMomentDate(date) {
  return isMoment(date)
    ? date.format("MMM Do, YYYY")
    : moment(date).format("MMM Do, YYYY");
}

export function scrollToElement(
  selector,
  options = { block: "center", inline: "center", scroll: true }
) {
  setTimeout(() => {
    const el = document.querySelector(selector);
    if (el) {
      el.focus();
      if (el.select) el.select();
      if (options.scroll) el.scrollIntoView({ behavior: "smooth", ...options });
    }
  }, 50);
}

export const Units = [
  {
    label: "Tons",
    value: "Tons",
  },
  {
    label: "Pieces",
    value: "Pieces",
  },
  {
    label: "Each",
    value: "Each",
  },
  {
    label: "Carton",
    value: "Carton",
  },
  {
    label: "Bags",
    value: "Bags",
  },
  {
    label: "Litres",
    value: "Litres",
  },
  {
    label: "Centiliter",
    value: "Centiliter",
  },
  {
    label: "Drum",
    value: "Drum",
  },
  {
    label: "Bundle",
    value: "Bundle",
  },
  {
    label: "KG",
    value: "kG",
  },
  {
    label: "G",
    value: "G",
  },
  {
    label: "Roll",
    value: "Roll",
  },
  {
    label: "Packets",
    value: "Packets",
  },
  {
    label: "Cups",
    value: "Cups",
  },
  {
    label: "Rubber",
    value: "Rubber",
  },
  {
    label: "Can",
    value: "Can",
  },
  {
    label: "Sachet",
    value: "Sachet",
  },
  {
    label: "Portion",
    value: "Portion",
  },
  {
    label: "Bottle",
    value: "Bottle",
  },
  {
    label: "Bowl",
    value: "Bowl",
  },
  {
    label: "Tin",
    value: "Tin",
  },
  {
    label: "Tuber",
    value: "Tuber",
  },

  {
    label: "Pack",
    value: "Pack",
  },
  {
    label: "Ampoules",
    value: "Ampoules",
  },
  {
    label: "Box",
    value: "Box",
  },
  {
    label: "Sachet",
    value: "Sachet",
  },
  {
    label: "Vial",
    value: "Vial",
  },
  {
    label: "Dozen",
    value: "Dozen",
  },
  {
    label: "Crate",
    value: "Crate",
  },
];

// if items is tons - the user should only choose tons or pieces
export function unitsResolver(options, chosen) {
  const selectOptions = [...options];
  try {
    if (!chosen) return selectOptions;

    if (["tons", "pieces"].includes(lowerCase(chosen))) {
      return selectOptions.filter((el) =>
        ["tons", "pieces"].includes(lowerCase(el.value))
      );
    } else {
      return selectOptions.filter(
        (el) => !["tons", "pieces"].includes(lowerCase(el.value))
      );
    }
  } catch (err) {
    console.log(err);
    return selectOptions;
  }
}

export const fetchActionsUtil = async (url, method, token, payload) => {
  let response = await fetch(url, {
    method: method,
    credentials: "include",
    body: JSON.stringify(payload),
    headers: {
      Accept: "Application/json",
      "Content-Type": "Application/json",
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  });
  if (!response.ok) {
    response = await response.json();
    // console.log(response.message);

    if (response.errors) {
      for (let error in response.errors) {
        toast.error(response.errors[error]);
      }
    }
    throw new Error(response?.message ? response?.message : response);
  }
  const res = await response.json();

  // console.log(res);
  // console.log(url);

  // const { data, message } = res;
  return res;
};

export const sendFormData = async (url, formData) => {
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    body: formData,
  });

  if (!response.ok) {
    response = await response.json();

    throw new Error(response.message);
  }

  const data = await response.json();
  return data;
};

export const printers = [
  { label: "A4 Size Printer", value: "A4 Size Printer" },
  { label: "Thermal Printer", value: "Thermal Printer" },
];

export const getDataForEdit = ({ qty, desc, itemMeasurements = [] }) => {
  try {
    qty = qty <= 0 ? 0 : qty;
    const tonsAndPcs = qtyFormat(qty, desc, itemMeasurements);
    const Tons = tonsAndPcs.split("-")[0];
    const Pieces = tonsAndPcs.split("-")[1];
    const formatType = tonsAndPcs.split("-")[2];

    // console.log(tonsAndPcs, desc, qty);

    return {
      Tons,
      Pieces,
      formatType,
    };
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const scrollToTop = () => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
};

export const damageStatusOptions = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Replaced By Vendor",
    value: "Replaced By Vendor",
  },
  {
    label: "Credit Note from Vendor",
    value: "Credit Note from Vendor",
  },
  {
    label: "Quarantined",
    value: "Quarantined",
  },
];

export const allAcountDetailIDs = [
  {
    data: [
      "CURRENT ASSET",
      "CASH AND CASH EQUIVALENT",
      "NON-CURRENT ASSETS",
      "FIXED ASSET",
      "ACCOUNTS RECEIVABLE",
      "COST OF SALES",
      "EXPENSES",
      "ACCUMULATED DEPRECIATION",
    ],
    num1: 1000,
    num2: 1999,
  },
  {
    data: [
      "ACCOUNTS PAYABLE",
      "CURRENT LIABILITIES",
      "NON-CURRENT LIABILITY",
      "LONG-TERM LIABILITIES",
    ],
    num1: 5000,
    num2: 5999,
  },
  {
    data: ["OWNER'S EQUITY"],
    num1: 3000,
    num2: 3999,
  },
  {
    data: ["INCOME", "OTHER INCOME"],
    num1: 4000,
    num2: 4999,
  },
];

export const calculateAccounts = (type, credit, debit) => {
  const accountOne = [
    {
      data: [
        "CURRENT ASSET",
        "CASH AND CASH EQUIVALENT",
        "NON-CURRENT ASSETS",
        "FIXED ASSET",
        "ACCOUNTS RECEIVABLE",
        "COST OF SALES",
        "EXPENSES",
        "ACCUMULATED DEPRECIATION",
      ],
      action: parseFloat(debit) - parseFloat(credit),
    },
    {
      data: [
        "ACCOUNTS PAYABLE",
        "CURRENT LIABILITIES",
        "NON-CURRENT LIABILITY",
        "LONG-TERM LIABILITIES",
        "OWNER'S EQUITY",
        "INCOME",
      ],
      action: parseFloat(credit) - parseFloat(debit),
    },
  ];

  let answer = 0;

  accountOne.every((d) => {
    if (d.data.some((e) => e === type)) {
      answer = d.action;
      return false;
    } else {
      return true;
    }
  });

  return answer;
};

export const displayAccountType = (mainType, amount, type) => {
  // console.log(mainType, amount, type);

  // GA - SWAP TYPE - if negative number
  if (amount < 0) {
    type = type === "debit" ? "credit" : "debit";
    amount = Math.abs(parseFloat(amount));
  }

  const accountOne = [
    {
      data: [
        "CURRENT ASSET",
        "CASH AND CASH EQUIVALENT",
        "NON-CURRENT ASSETS",
        "FIXED ASSET",
        "ACCOUNTS RECEIVABLE",
        "COST OF SALES",
        "EXPENSES",
        "ACCUMULATED DEPRECIATION",
      ],
      action: type === "debit" ? amount : 0,
    },
    {
      data: [
        "ACCOUNTS PAYABLE",
        "CURRENT LIABILITIES",
        "NON-CURRENT LIABILITY",
        "LONG-TERM LIABILITIES",
        "OWNER'S EQUITY",
        "INCOME",
      ],
      action: type === "credit" ? amount : 0,
    },
  ];

  let answer = 0;

  accountOne.every((d) => {
    if (d.data.some((e) => e === mainType)) {
      answer = d.action;
      return false;
    } else {
      return true;
    }
  });

  return answer;
};

export const calculateAccountsTotal = (arrayData) => {
  const accountOne = [
    {
      data: [
        "CURRENT ASSET",
        "CASH AND CASH EQUIVALENT",
        "NON-CURRENT ASSETS",
        "FIXED ASSET",
        "ACCOUNTS RECEIVABLE",
        "COST OF SALES",
        "EXPENSES",
        "ACCUMULATED DEPRECIATION",
      ],
      action: "Debit",
    },
    {
      data: [
        "ACCOUNTS PAYABLE",
        "CURRENT LIABILITIES",
        "NON-CURRENT LIABILITY",
        "LONG-TERM LIABILITIES",
        "OWNER'S EQUITY",
        "INCOME",
      ],
      action: "Credit",
    },
  ];

  const allTotalAmount = {
    Credit: 0,
    Debit: 0,
  };

  arrayData.forEach((ele) => {
    accountOne.forEach((d) => {
      if (d.data.some((e) => e === ele[1]["key"])) {
        // GA SWAP -
        let action = d.action;
        if (parseFloat(ele[1]["totalAmount"]) < 0) {
          action = d.action === "Debit" ? "Credit" : "Debit";
        }
        allTotalAmount[action] += parseFloat(ele[1]["totalAmount"]);
      }
    });
  });

  // console.log(allTotalAmount, "kdk");
  return allTotalAmount;
};

export const returnAmountTotal = (arr, key) => {
  let answer = 0;
  arr?.every((d) => {
    if (d.key === key) {
      answer = d.totalAmount;
      return false;
    } else {
      return true;
    }
  });
  return answer;
};

// timezone is messing up the code
export function formatDate(date, content = "dd MMM, yyyy") {
  try {
    if (date) {
      if (isMoment(date)) return date.format("Do MMM, YYYY");

      const dateWithoutTimezone = new Date(
        date?.slice ? date.slice(0, -1) : date
      );

      return format(dateWithoutTimezone, content);
    } else {
      return "...";
    }
  } catch (err) {
    console.log(err);
    return date;
  }
}

export const getStockQuantityBefore = (text) => {
  try {
    if (text && text.includes("/")) {
      return currency(text.split("/")[0], {
        symbol: "",
        precision: 2,
        format: formatForQty,
        negativePattern: "0",
      }).format();
    } else {
      return 0;
    }
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const getStockQuantityAfter = (text) => {
  try {
    if (text && text.includes("/")) {
      return currency(text.split("/")[1], {
        symbol: "",
        precision: 2,
        format: formatForQty,
        negativePattern: "0",
      }).format();
    } else {
      return 0;
    }
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const formatCurrencyToNumber = (value) => {
  return currency(value, {
    symbol: "",
    separator: "",
  }).format();
};

export const returnRowError = (rowName, index) => {
  return `${rowName} in the ${index + 1}${index === 0 ? "st" : index === 1 ? "nd" : index === 2 ? "rd" : "th"
    } row or delete the row`;
};

export const defaultRequisitionTypes = [
  {
    label: "Inventory",
    value: "Inventory",
  },
  {
    label: "Fund Request",
    value: "Fund Request",
  },
  {
    label: "Normal",
    value: "Normal",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export function addSaleItem({
  selectedItem,
  selectedCustomer,
  itemMeasurements,
}) {
  return new Promise(async (resolve, reject) => {
    try {
      //  selectedItem.quantityInStock = selectedItem.Quantity;
      const values = {
        Quantity: selectedItem.Quantity,
        UnitPrice: selectedItem.UnitPrice,
        PriceSold: selectedItem.UnitPrice,
        durationNumber: 0,
        durationValue: "Month",
        Warranty: false,
        Discount: 0,
        saleType: "Each",
        requirePermission: false,
        Size: !isEmpty(selectedItem?.sizes)
          ? selectedItem.sizes[0]?.Size_Color
          : "",
      };

      // Check Customer type to get correct unit price
      const transType = lowerCase(selectedCustomer?.TransType);
      selectedItem.UnitPrice =
        transType === "distributor"
          ? selectedItem.Vat_5
          : ["wholesaler", "wholeseler"].includes(transType)
            ? selectedItem.Tax
            : selectedItem.UnitPrice;

      const unitPriceInTons = selectedItem.UnitPrice;
      const unitPriceInPcs = selectedItem.Tax;

      if (Number(values.Quantity) > selectedItem?.quantityInStock) {
        return reject({
          errors: {
            Quantity: `Not enough item in stock`,
          },
        });
      }

      // check if they have enough sizes in stock
      if (values.Size && appSettings.hasSize) {
        const selectedSize = selectedItem.sizes.find(
          (el) => el.Size_Color === values.Size
        );
        if (Number(values.Quantity) > selectedSize.Quantity) {
          return reject(
            "Size",
            `Not enough item in stock for the selected Size - ${values.Size}`
          );
        }
      }

      const theUnitPrice =
        lowerCase(values.saleType) === "tons" ||
          lowerCase(values.saleType) !== "pieces"
          ? unitPriceInTons
          : unitPriceInPcs;

      // console.log(theUnitPrice);

      /* values.Warrant_Duration = `${values.durationNumber} ${
        values.durationValue
      }`; */

      values.Warrant_Duration = values?.Warrant_Duration || "";

      values.PriceSold = currency(values.PriceSold, {
        symbol: "",
        separator: "",
      }).format();

      values.SubTotal = currency(values.PriceSold, {
        symbol: "",
        separator: "",
      })
        .multiply(
          lowerCase(values.saleType) === "tons"
            ? pcsToTons(
              values.Quantity,
              selectedItem.Item_Desc,
              itemMeasurements
            )
            : values.Quantity
        )
        .format();

      values.Discount = currency(values.Discount, {
        symbol: "",
        separator: "",
      })
        .multiply(
          lowerCase(values.saleType) === "tons"
            ? pcsToTons(
              values.Quantity,
              selectedItem.Item_Desc,
              itemMeasurements
            )
            : values.Quantity
        )
        .format();

      // Get Profit Based on batch we are picking from---------------------------------------------------
      const quantityToSell = values.Quantity;
      let remainder = quantityToSell;
      const updatedBatchDataToSave = [];
      let measurement = itemMeasurements.find(
        (measurement) => measurement.Size === selectedItem.Item_Desc
      );

      //  console.log(measurement);

      if (
        lowerCase(values.saleType) !== "tons" &&
        lowerCase(values.saleType) !== "pieces"
      ) {
        measurement = {
          Quantity: 1,
        };
      }

      if (lowerCase(values.saleType) === "tons" && !measurement) {
        return toast.error("This item is not sold in Tons");
      }

      const priceSoldPerUnit =
        lowerCase(values.saleType) === "tons"
          ? currency(values.PriceSold)
            .divide(measurement?.Quantity || 1)
            .format()
          : values.PriceSold;

      for (let [index, batch] of selectedItem.batches.entries()) {
        console.log(index);
        const pcsUnitCost = currency(batch.UnitCost, {
          symbol: "",
          separator: "",
        })
          .divide(measurement?.Quantity || 1)
          .format();

        //    console.log(pcsUnitCost, batch.UnitCost, measurement.Quantity);

        if (Number(batch.Quantity) >= Number(remainder)) {
          // means we are at the last
          updatedBatchDataToSave.push({
            unitCost: pcsUnitCost,
            quantity: remainder,
            totalUnitCost: currency(pcsUnitCost, {
              symbol: "",
              separator: "",
            })
              .multiply(remainder)
              .format(),
            totalUnitPrice: currency(priceSoldPerUnit, {
              symbol: "",
              separator: "",
            })
              .multiply(remainder)
              .format(),
            totalMargin: currency(selectedItem?.Margin || 0)
              .divide(measurement?.Quantity || 1)
              .multiply(remainder).value,
            totalIncentive: currency(selectedItem?.Incentive || 0)
              .divide(measurement?.Quantity || 1)
              .multiply(remainder).value,
          });

          if (lowerCase(values.saleType) === "pieces") {
            values.UnitCost = pcsUnitCost;
          }
          break;
        } else {
          updatedBatchDataToSave.push({
            unitCost: pcsUnitCost,
            quantity: batch.Quantity,
            totalUnitCost: currency(pcsUnitCost, {
              symbol: "",
              separator: "",
            })
              .multiply(batch.Quantity)
              .format(),
            totalUnitPrice: currency(priceSoldPerUnit, {
              symbol: "",
              separator: "",
            })
              .multiply(batch.Quantity)
              .format(),
            totalMargin: currency(selectedItem?.Margin || 0)
              .divide(measurement?.Quantity || 1)
              .multiply(batch.Quantity).value,
            totalIncentive: currency(selectedItem?.Incentive || 0)
              .divide(measurement?.Quantity || 1)
              .multiply(batch.Quantity).value,
          });

          remainder = Number(
            currency(remainder, {
              symbol: "",
              separator: "",
            })
              .subtract(batch.Quantity)
              .format()
          );
        }
      }
      const totalProfit = updatedBatchDataToSave
        .map((el) =>
          currency(el.totalUnitPrice)
            .subtract(el.totalUnitCost)
            .subtract(el.totalIncentive)
            .format()
        )
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        );
      values.Profit = totalProfit;

      //---------------------------------
      values.Total_Incentive = updatedBatchDataToSave
        .map((el) => el.totalIncentive)
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        );

      //  For Service Overwrite ----------------------------------------------------------
      if (lowerCase(selectedItem.Item_Type) === "service") {
        values.Profit = currency(priceSoldPerUnit)
          .subtract(selectedItem.UnitCost)
          .multiply(values.Quantity).value;
        values.Total_Incentive = currency(selectedItem?.Incentive).multiply(
          values.Quantity
        ).value;
      }

      resolve({
        ...selectedItem,
        Serial_Number: values?.Size ? values.Size : selectedItem.Item_Desc,
        ...values,
      });
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}

export const analyticsOptions = [
  {
    label: "Last 1 Year",
    value: "year",
  },
  {
    label: "This Year",
    value: "this-year",
  },
  {
    label: "Select Year",
    value: "select-year",
  },
  {
    label: "Last 7 Days",
    value: "7",
  },
  {
    label: "Last 28 Days",
    value: "28",
  },
  {
    label: "Last 90 Days",
    value: "90",
  },
];

export const analyticsOptionsVariant2 = [
  {
    label: "All Time",
    value: "all-time",
  },
  /*  {
    label: "This Year",
    value: "all-time",
  }, */
  {
    label: "Last 7 Days",
    value: "7",
  },
  {
    label: "Last 28 Days",
    value: "28",
  },
  {
    label: "Last 90 Days",
    value: "90",
  },
];
export function round(value, step) {
  step || (step = 1.0);
  var inv = 1.0 / step;
  return Math.round(value * inv) / inv;
}

export const didYouKnowOptions = [
  {
    value: "Walk In",
    label: "Walk In",
  },
  {
    value: "Flyers",
    label: "Flyers",
  },
  {
    value: "Radio",
    label: "Radio",
  },
  {
    value: "Bill board",
    label: "Bill board",
  },
  {
    value: "Friends",
    label: "Friends",
  },
  {
    value: "Television",
    label: "Television",
  },
  {
    value: "Website",
    label: "Website",
  },
  {
    value: "Search Engine",
    label: "Search Engine (Google, Bing, Yahoo etc)",
  },
  {
    value: "Social Media",
    label: "Social Media",
  },
];

export const customerTypeOptions = [
  {
    label: "Walk In",
    value: "Walk In",
  },
  {
    label: "Retail",
    value: "Retail",
  },
  {
    label: "Wholesaler",
    value: "Wholesaler",
  },
  {
    label: "Distributor",
    value: "Distributor",
  },
];

export const documentTypeOptions = [
  {
    label: "Image",
    value: "image",
  },
  {
    label: "Word Document",
    value: "doc",
  },
  {
    label: "Excel Document",
    value: "excel",
  },
  {
    label: "Power Point",
    value: "power-point",
  },
  {
    label: "Pdf",
    value: "pdf",
  },
  {
    label: "Others",
    value: "others",
  },
];

export const initialGeneralSettings = {
  itemsRequiresSerialNumber: false,
  salesInventoryName: "Sales Inventory",
  approvedImboundPo: false,
  convertToTonsAndPcs: false,
  enableCreditLimit: false,
  enableCreditPaymentForWalkIn: true,
  pendingInvoiceType: "Proforma Invoice",
  hideDiscountColumn: false,
  addSignatureOnInvoice: false,
  addItemDescriptionOnInvoice: false,
  unitPriceTextOnInvoice: "Price Sold",
  isPurchasePriceRequired: false,
  presetItemValues: true,
  hideUnitPrice: false,
  hideDiscount: false,
  presetFinalSellingPrice: false,
  linkInvoiceToPayment: false,
  hasfreightVendor: false,
  expenseRequiresAdminApproval: false,
  linkPaymentToInvoice: false,
  enableDrumAccount: false,
  costPerDrum: 4000,
  drumAccountID: "",
  drumAccountDescription: "",
  hideUnitCost: false,
  balanceInExpenseFromAccount: false,
  dollarInBaseCurrency: 800,
  Item_Type: "Inventory",
  invoiceNoStockItem: false,
  requiredSignatoryStaff_ID: "",
  fundRequestMandatorySignatory: [
    {
      department: "",
      jobGrade: "",
      staff: "",
      limit: 0,
      limitType: "fixed",
    },
    {
      department: "",
      jobGrade: "",
      staff: "",
      limit: 0,
      limitType: "fixed",
    },
  ],
  materialRequestMandatorySignatory: [
    {
      department: "",
      jobGrade: "",
      staff: "",
    },
    {
      department: "",
      jobGrade: "",
      staff: "",
    },
  ],
  allowanceMandatorySignatory: [
    {
      department: "",
      jobGrade: "",
      staff: "",
      limit: 0,
      limitType: "fixed",
    },
    {
      department: "",
      jobGrade: "",
      staff: "",
      limit: 0,
      limitType: "fixed",
    },
  ],
  retirementDurationNumber: "",
  retirementDurationValue: "",
  poNumber: false,
  processStaffSalaryInHr: false,
  expiryFilterDurationNumber: 3,
  expiryFilterDurationValue: "Month",
  enableGeneralDiscount: false,
  generalDiscountType: "Fixed",
  generalDiscountPercentage: 0,
  generalDiscount: 0,
  taxOptions: [
    {
      name: "None",
      percentage: 0,
    },
    {
      name: "VAT",
      percentage: 5,
    },
    {
      name: "WHT",
      percentage: 5,
    },
    {
      name: "NCD",
      percentage: 1,
    },
  ],
  numberOfMandatoryApprovalsBeforeApproveAndClose: 0,
  WarehouseRecieveAssurance: false,
  RM_FPApproval: false,
  CofAApproval: false,
  supplyNow: true,
  storeName: "...",
  incentiveAccountID: "",
  incentiveAccountDescription: "",
  Batch_Name: "Batch Name",
  selectBatchDuringInvoicing: false,
  isFarm: false,
  editVendorPaymentRequiresAdminApproval: false,
  deleteVendorPaymentRequiresAdminApproval: false,
  editJournalRequiresAdminApproval: false,
  convertableUOM: "Tons",
  inventorySelector: "Single",
  canBackDate: true,
  enableInvoiceSignatory: false,
  /*  invoiceSignatoryStaffName: "",
  invoiceSignatoryStaffTitle: "",
  proformaInvoiceTitle: "", */
  itemStatusSettings: false,
  storeEmail: "",
  selectBatchWhenIssuingItem: false,
  analyseDataFrom: 6,
  forcastDataFor: 3,
  defaultInvoicePaymentType: "",
  createInvoiceAfterRestock: false,
  hideUnitPriceInStore: true,
  customerCanEditPriceInCart: false,
  checkoutButtonText: "...",
  canAddBatchName: false,
  showBatchNameOnInvoice: false,
  lockItemsInSupplyCenter: false,
  disablePaymentAtCheckout: false,
  invoiceSignatories: [
    {
      invoiceSignatoryStaffName: "",
      invoiceSignatoryStaffTitle: "",
      invoiceSignatoryStaffImage: "",
    },
  ],
  lockApprovedItemsInProforma: false,
  addCustomerSignatureOnInvoice: false,
  withInvoiceCatBalance: false,
  addDriverInfoBeforeSupply: false,
  lockFinalSellingPrice: false,
  customerVerification: false,
  inventoryManagement: "FIFO",
  pharmacySetting: false,
  itemSearchCriteria: "Item Code",
  procurementName: "Procurement",
  largeQuickLink: false,
  standardProcurementPlan: "Default Plan",
  restockRequiresApproval: false,
  dueIn: 1,
  secondLineFundRequestApproval: [
    {
      department: "",
      jobGrade: "",
      staff: "",
      limit: 0,
      limitType: "fixed",
    },
  ],
  vendorInvoiceRequiresApproval: false,
  vendorDueIn: 1,
  expiredItemsRecipientEmail: [
    {
      email: "",
    },
  ],
  insuranceCreditLines: [
    {
      creditLine: "",
    },
  ],
  prevailingCurrency: "NGN",
  deploymentCurrencies: [],
  restockRequiresPONumber: false,
  invoicingType: "Standard",

  sortByArchieve: "",
  vendorVerificationRequiresApproval: false,
  vendorDeleteRequiresApproval: false,
  enableSellingPriceInRequisitionInvoice: false,
  serviceInvoiceRequiresApproval: true,
  waybillSignatories: [
    {
      name: "",
    },
  ],
  numberOfDaysToRefill: 0,
  refillPercentage: 0,
  numberOfDaysStockIsNeeded: 0,
  customerApprovePendingOnlineOrders: false,
  minimumStockByReorderLevel: false,
  recordCostofGoodsDuring: "Restocking",
  recordCostOfSalesBy: "batchUsed",
  quantityLabel: "Quantity",
  excludeBranchesInSalesByInvoice: false,
  deliveryInformationOnInvoice: false,
  maxBackDateCustom: ''
};

export const consumptionTypes = [
  {
    label: "Food",
    value: "Food",
  },
  {
    label: "Drugs",
    value: "Drugs",
  },
  {
    label: "Veterinary",
    value: "Veterinary",
  },
  {
    label: "Others",
    value: "Others",
  },
];

export const sortOptions = [
  { label: "Name: A to Z", value: "name-asc" },
  { label: "Name: Z to A", value: "name-desc" },
  { label: "Manufacturer: A to Z", value: "product-asc" },
  { label: "Manufacturer: Z to A", value: "product-desc" },
  { label: "Category: A to Z", value: "category-asc" },
  { label: "Category: Z to A", value: "category-desc" },
  { label: "Price: Low to High", value: "lowest-price" },
  { label: "Price: High to Low", value: "highest-price" },
];

export const itemLevelOptions = [
  { label: "All", value: "" },
  { label: "Above Mininum", value: "aboveMinimum" },
  { label: "Below Mininum", value: "belowMinimum" },
  { label: "Above Maximum", value: "aboveMaxmum" },
  { label: "Below Maximum", value: "belowMaxmum" },
];

export const initialServiceSettings = {
  warehouse: true,
  production: true,
  formsAndApproval: true,
  store: true,
};

export const getUniqueObjectsFromArray = (ware) => {
  if (isArray(ware)) {
    return Array.from(new Set(ware.map((obj) => JSON.stringify(obj)))).map(
      (str) => JSON.parse(str)
    );
  } else return "Parameter not an array";
};
export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function cleanUpItemForCart({ item }) { }

export const creditLimitOptions = [
  { value: "", label: "" },
  { value: "One-time", label: "One-time" },
  { value: "Date range", label: "Date range" },
];

export const paymentStatusOptions = [
  { label: "Pending", value: "Pending" },
  {
    label: "Confirmed",
    value: "Confirmed",
  } /*
  { label: "Confirmed Paystack", value: "Confirmed Paystack" }, */,
];

export const acceptedByCustomerStatusOptions = [
  { value: "", label: "All" },
  { value: "Accepted", label: "Accepted" },
  { value: "Rejected", label: "Rejected" },
];

export const transactionFromOptions = [
  { value: "", label: "All" },
  { value: "User", label: "User" },
  { value: "Online Store", label: "Online Store" },
];

export const calculateMarkUp_Incentive_Margin = ({
  UnitCost,
  PriceSold,
  Margin,
  marginType,
  marginPercentage,
  Incentive,
  incentiveType,
  incentivePercentage,
  /*   markUp,
  markUpType,
  markUpPercentage, */
}) => {
  let calculatedMarkUp = currency(PriceSold).subtract(UnitCost).value;
  let calculatedIncentive = Incentive;
  let calculatedMargin = Margin;
  // ---------------------------------------------
  /* if (markUpType === "Percentage") {
    calculatedMarkUp = currency(markUpPercentage)
      .divide(100)
      .multiply(UnitCost).value;
  } */

  if (incentiveType === "Percentage") {
    calculatedIncentive = currency(incentivePercentage)
      .divide(100)
      .multiply(calculatedMarkUp).value;
  }

  if (marginType === "Percentage") {
    calculatedMargin = currency(marginPercentage)
      .divide(100)
      .multiply(calculatedMarkUp).value;
  }
  // ----------------------------------------------
  return {
    calculatedMarkUp,
    calculatedIncentive,
    calculatedMargin,
  };
};

export const formatNumberSystem = (prefix = "", number = "") => {
  return `${prefix ? `${prefix}-` : ""}${number ? padStart(number, 4, 0) : ""}`;
};

const secretPass = "XkhZG4fW2t2Wwrfsh4343rfsgh2326343fsvvgrw424ddgdhgsde4242";

export const encryptData = (text) => {
  const data = CryptoJS.AES.encrypt(text, secretPass).toString();

  return data;
};

export const decryptData = (text) => {
  const bytes = CryptoJS.AES.decrypt(text, secretPass);
  const data = bytes.toString(CryptoJS.enc.Utf8);
  return data;
};
export const formDataForFormBuider = (payload, formData = new FormData()) => {
  // mark all files as upload
  function replacer(key, value) {
    if (String(key) === "isUploaded") {
      return JSON.stringify(true);
    }
    if (String(key) === "file") {
      return "";
    }
    return value;
  }

  const payloadModified = JSON.stringify(payload, replacer);
  formData.append("payload", payloadModified);
  //  console.log(payload);

  // GET FILES FOR UPLOAD --------------------------------------
  const page = payload.Template[0];
  const attachments = page?.children
    .filter((el) => el.type === "attachmentElement")
    .map((el) => el.props.content);

  // console.log(attachments);

  if (attachments) {
    const attachmentsEl = attachments.flat();

    if (attachmentsEl) {
      attachmentsEl.forEach((file) => {
        if (Boolean(file?.file)) {
          formData.append(
            "attachments",
            file.file,
            `${file.fileId}.${file.ext}`
          );
        }
      });
    }
  }

  const images = page?.children
    .filter((el) => el.type === "imageElement")
    .map((el) => el.props.content);

  if (images) {
    images.forEach((file) => {
      if (Boolean(file?.file)) {
        formData.append("attachments", file.file, `${file.fileId}.${file.ext}`);
      }
    });
  }

  const profileImages = page?.children
    .filter((el) => el.type === "profileElement")
    .map((el) => el.props.profilePicture.props.content);

  if (profileImages) {
    profileImages.forEach((file) => {
      if (Boolean(file?.file)) {
        formData.append("attachments", file.file, `${file.fileId}.${file.ext}`);
      }
    });
  }

  return formData;
};

export const slugify = (str) => {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
};

export const formTypes = [
  {
    label: "Organisation",
    value: "Organisation",
  },
  {
    label: "Public",
    value: "Public",
  },
];

export const initialServiceItem = () => ({
  Bar_Code: nanoid(10, "number"),
  Serial_Number: "",
  Item_Name: "",
  Product_Name: "",
  Product_Model: "",
  Cat_Name: "",
  Item_Desc: "",
  Usage: "Sales",
  Quantity: 1,
  UnitCost: 0,
  UnitPrice: 0,
  Item_Type: "Service",
  Margin: 0,
  Incentive: 0,
  PriceSold: 0,
  durationNumber: 0,
  durationValue: "Month",
  Warranty: false,
  Discount: 0,
  saleType: "Each",
  requirePermission: false,
  Size: "",
  Warrant_Duration: "",
  SubTotal: 0,
  Profit: 0,
  Total_Incentive: 0,
});
export const statusUpdateClass = (Status) => {
  return Status === "Reject"
    ? "view_status_reject"
    : Status === "Pending"
      ? "view_status_partly"
      : Status === "Approve"
        ? "view_status_all"
        : "view_status_all";
};

const excludedCoversionFields = [
  "quantity",
  "qty",
  "conversionamount",
  "baseconversionamount",
  "transactionid",
  "bar_code",
  "pendingtransaction",
  "jobnumber",
  "cust_id",
  "duePaymentTransactionID",
  "trans_id",
  "transid",
  "invoiceno",
  "vendor_id",
  "reorder_level",
  "sizes",
  "ref",
  "payto",
  "account",
  "accountid",
  "jobaccountid",
  "particulers",
  "reference",
  "runningbalance",
  "serial_number",
  "serialnum",
  "vendor",
  "freightvendor_id",
  "currentnumber",
  "transactionidfordelete",
  "id",
  "openingAccountID",
  "warrant_duration",
];

export const convertFromBaseCurrency = ({ data, conversionAmount }) => {
  return mapValues(data, (value, key) => {
    if (
      (typeof value === "object" || typeof value === "boolean") &&
      !Array.isArray(value)
    ) {
      //----
    } else if (
      value &&
      isNumber(Number(value)) &&
      !isNaN(Number(value)) &&
      !excludedCoversionFields.includes(String(key).toLowerCase())
    ) {
      let itemConversionAmount = data?.conversionAmount
        ? data?.conversionAmount
        : conversionAmount;

      if (
        [
          "PriceSold",
          "SubTotal",
          "shippingCost",
          "otherCharges",
          "VAT",
        ].includes(key)
      ) {
        return currency(value).divide(conversionAmount).value;
      }
      return currency(value).divide(itemConversionAmount).value;
    } else if (Array.isArray(value)) {
      //console.log(value, key);
      //--------------------------------------
      const arrValue = value.map((el) => {
        let itemConversionAmount = el?.conversionAmount
          ? el?.conversionAmount
          : conversionAmount;
        return mapValues(el, (valueA, keyA) => {
          if (
            (typeof valueA === "object" || typeof valueA === "boolean") &&
            !Array.isArray(valueA)
          ) {
            //----
          } else if (
            valueA &&
            isNumber(Number(valueA)) &&
            !isNaN(Number(valueA)) &&
            !excludedCoversionFields.includes(String(keyA).toLowerCase())
          ) {
            if (["PriceSold", "SubTotal"].includes(keyA)) {
              return currency(valueA).divide(conversionAmount).value;
            }
            return currency(valueA).divide(itemConversionAmount).value;
          }
          return valueA;
        });
      });
      //  console.log(arrValue, "---");
      return arrValue;
      //------------------------------------------
    }
    return value;
  });
};

export const convertToBaseCurrency = ({ data, conversionAmount }) => {
  return mapValues(data, (value, key) => {
    // console.log(key, value, typeof value);
    if (
      (typeof value === "object" || typeof value === "boolean") &&
      !Array.isArray(value)
    ) {
      //----
    } else if (
      value &&
      isNumber(Number(value)) &&
      !isNaN(Number(value)) &&
      !excludedCoversionFields.includes(String(key).toLowerCase())
    ) {
      let itemConversionAmount = data?.conversionAmount
        ? data?.conversionAmount
        : conversionAmount;
      if (["PriceSold", "SubTotal"].includes(key)) {
        return currency(value).multiply(conversionAmount).value;
      }
      return currency(value).multiply(itemConversionAmount).value;
    } else if (Array.isArray(value)) {
      //console.log(value, key);
      //--------------------------------------
      const arrValue = value.map((el) => {
        let itemConversionAmount = el?.conversionAmount
          ? el?.conversionAmount
          : conversionAmount;
        return mapValues(el, (valueA, keyA) => {
          if (
            (typeof valueA === "object" || typeof valueA === "boolean") &&
            !Array.isArray(valueA)
          ) {
            //----
          } else if (
            valueA &&
            isNumber(Number(valueA)) &&
            !isNaN(Number(valueA)) &&
            !excludedCoversionFields.includes(String(keyA).toLowerCase())
          ) {
            //Base Conversion
            if (["PriceSold", "SubTotal"].includes(keyA)) {
              return currency(valueA).multiply(conversionAmount).value;
            }
            return currency(valueA).multiply(itemConversionAmount).value;
          }
          return valueA;
        });
      });
      //  console.log(arrValue, "---");
      return arrValue;
      //------------------------------------------
    }
    return value;
  });
};

export const maintainceData = [
  {
    label: "",
    value: "",
  },
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

export const Validity = [
  {
    label: "",
    value: "",
  },
  {
    label: "1 Month",
    value: "1",
  },
  {
    label: "2 Months",
    value: "2",
  },
  {
    label: "3 Months",
    value: "3",
  },
  {
    label: "4 Months",
    value: "4",
  },
  {
    label: "5 Months",
    value: "5",
  },
  {
    label: "6 Months",
    value: "6",
  },
  {
    label: "7 Months",
    value: "7",
  },
  {
    label: "8 Months",
    value: "8",
  },
  {
    label: "9 Months",
    value: "9",
  },
  {
    label: "10 Months",
    value: "10",
  },
  {
    label: "11 Months",
    value: "11",
  },
  {
    label: "12 Months",
    value: "12",
  },
];

export const pageSizes = [
  {
    label: "A0",
    value: "A0",
  },
  {
    label: "A1",
    value: "A1",
  },
  {
    label: "A2",
    value: "A2",
  },
  {
    label: "A3",
    value: "A3",
  },
  {
    label: "A4",
    value: "A4",
  },
  {
    label: "A5",
    value: "A5",
  },
];

export const getUserThatRequestedToMe = ({
  requisitionTracks = [],
  receivedby,
}) => {
  // reverse to find latest --------------------------------------------
  const reversedTracks = cloneDeep(requisitionTracks).reverse();
  const foundIndex = reversedTracks.findIndex(
    (el) => el.receivedby === receivedby
  );
  const foundUser = reversedTracks[foundIndex]?.sentbyUser?.Name;
  const lastUser = last(requisitionTracks)?.sentbyUser?.Name;
  return foundUser ? foundUser : lastUser;
};

export const amountTypes = [
  { label: "", value: "" },

  {
    label: "Percentage",
    value: "Percentage",
  },
  {
    label: "Fixed",
    value: "Fixed",
  },
];

/* export const resolveUserRoles = ({ roles, navItems, parent = "" }) => {
  return navItems
    .filter((el) => {
      const foundRole = roles.find(
        (role) => role.name === el.name && role.parent == parent
      );
      if (foundRole) {
        return foundRole?.checked;
      }
      return false;
    })
    .map((el) => {
      if (el.childRoutes) {
        el.childRoutes = el.childRoutes.filter((childRoute) => {
          const foundRole = roles.find(
            (role) => role.name === childRoute.name && role.parent == el.name
          );
          if (foundRole) {
            return foundRole?.checked;
          }
          return false;
        });
      }

      return {
        ...el,
        childRoutes: el.childRoutes,
      };
    });
}; */

/* export const sortOptions = [
  // { label: "Name: A to Z", value: "name-asc" },
  // { label: "Name: Z to A", value: "name-desc" },
  // { label: "Manufacturer: A to Z", value: "product-asc" },
  // { label: "Manufacturer: Z to A", value: "product-desc" },
  // { label: "Category: A to Z", value: "category-asc" },
  // { label: "Category: Z to A", value: "category-desc" },
  { label: "Sort By Price", value: "" },
  { label: "Price: Low to High", value: "lowest-price" },
  { label: "Price: High to Low", value: "highest-price" },
]; */

export const electronPrint = async ({ url }) => {
  try {
    const printData = await fetchActionsUtil(url, "GET");
    const { ipcRenderer } = window.require("electron");
    ipcRenderer.send("PRINT_FROM_JSON", { printData });
  } catch (err) {
    console.log(err);
  }
};

export const formatForQty = (currency, options) => {
  const decimal = currency.cents();
  let whole = currency.dollars();

  // Add Comma
  if (options.separator) {
    whole = whole.toLocaleString();
  }

  return `${whole}${decimal != 0 ? `.${decimal}` : ""}`;
};

export const copyLink = ({ link, toast }) => {
  const textarea = document.createElement("textarea");
  textarea.textContent = link;
  textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
  document.body.appendChild(textarea);
  textarea.select();
  textarea.setSelectionRange(0, 99999);
  document.execCommand("copy");
  document.body.removeChild(textarea);

  toast?.success("Link Copied");
};

export const getDomainOrigin = () => {
  const url = new URL(window.location.href);
  return url.origin;
};

export const headerWithCompanyDetails = ({
  backendUrl,
  company,
  size,
  headerName,
}) => {
  return (
    <div className="d-flex justify-content-between mt-4">
      <div>
        <img
          src={`${backendUrl}/images/company-logo.png`}
          alt="Company Logo"
          // className="logo"
          width={size}
        />
      </div>
      {headerName && (
        <div className="fw-4">
          <h4>{headerName}</h4>
        </div>
      )}
      <div>
        {company?.CompName || (
          <div className="fw-bold">
            <p className="fw-bold"> {company.CompName} </p>
          </div>
        )}

        {company?.AddressLine1 && <div> {company.AddressLine1} </div>}

        {company?.AddressLine2 && <div> {company.AddressLine2} </div>}

        {company?.Phone && <div>Tel: {company.Phone} </div>}

        {company?.Email && <div>Email: {company.Email} </div>}

        {company.Website && <div>Website: {company.Website} </div>}

        {company?.BranchAddressLine1 && (
          <div>BRANCH OFFICE: {company.BranchAddressLine1} </div>
        )}

        {company?.BranchAddressLine2 && (
          <div>{company.BranchAddressLine2} </div>
        )}

        {company.BranchPhone && <div>Tel: {company.BranchPhone} </div>}

        {company.BranchEmail && <div>Email: {company.BranchEmail} </div>}

        {company.BranchWebsite && <div>Website: {company.BranchWebsite} </div>}
      </div>
    </div>
  );
};

export const footers = () => {
  return (
    <footer className="d-flex py-3 align-items-center justify-content-center text-center">
      <p className="mb-0">
        ©{new Date().getFullYear()}{" "}
        <a
          href="https://invexerp.excellentbridge.com/"
          target="_blank"
          className="text-decoration-underline"
          rel="noreferrer"
        >
          Excellentbridge Technologies
        </a>
        <br />
        <small>Version {process.env?.REACT_APP_VERSION}</small>
      </p>
    </footer>
  );
};

export function getNumberFromString(value) {
  value = cloneDeep(value);
  if (typeof value === "number" && !isNaN(value)) {
    return value;
  }
  if (typeof value === "string" && !isNaN(value) && !isNaN(parseFloat(value))) {
    return parseFloat(value);
  }
  return 1;
}

export function isValidDate(dateString) {
  if (!dateString) {
    return false;
  }
  const date = new Date(dateString);

  return date instanceof Date && !isNaN(date);
}

// Array of months with label and value (month index)
export const months = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "Date Range",
    label: "Date Range",
  },
  { label: "January", value: 0 },
  { label: "February", value: 1 },
  { label: "March", value: 2 },
  { label: "April", value: 3 },
  { label: "May", value: 4 },
  { label: "June", value: 5 },
  { label: "July", value: 6 },
  { label: "August", value: 7 },
  { label: "September", value: 8 },
  { label: "October", value: 9 },
  { label: "November", value: 10 },
  { label: "December", value: 11 },
];

// Function to get start and end dates for a month in a specific year
export function getStartAndEndOfMonth(month, year) {
  if (!month) {
    throw new Error("Invalid month label");
  }

  const start = startOfMonth(new Date(year, month));
  const end = endOfMonth(new Date(year, month));

  return { start, end };
}

// Example usage
// const { start, end } = getStartAndEndOfMonth('March', 2024);
// console.log('Start of March 2024:', start);
// console.log('End of March 2024:', end);

export function dateIsBeforeMaxBackDate({
  dateSelected,
  maxBackDateDurationNumber,
  maxBackDateCustom,
  maxBackDateDurationValue
}) {

  /* console.log(dateSelected,
    maxBackDateDurationNumber,
    maxBackDateCustom,
    maxBackDateDurationValue) */

  if (!maxBackDateDurationValue) return true

  let resolvedDateSelected;

  if (maxBackDateDurationValue === 'Custom Date') {
    resolvedDateSelected = new Date(maxBackDateCustom)
  } else if (maxBackDateDurationValue === 'Start of Year') {
    resolvedDateSelected = startOfYear(new Date())
  } else {
    resolvedDateSelected =
      maxBackDateDurationValue === "Day"
        ? addDays(new Date(dateSelected), -Number(maxBackDateDurationNumber))
        : maxBackDateDurationValue === "Week"
          ? addWeeks(new Date(dateSelected), -Number(maxBackDateDurationNumber))
          : maxBackDateDurationValue === "Month"
            ? addMonths(new Date(dateSelected), -Number(maxBackDateDurationNumber))
            : new Date(dateSelected);
  }

  // console.log(new Date(dateSelected), ",,,,,,,", new Date(resolvedDateSelected),)

  return compareAsc(new Date(dateSelected), new Date(resolvedDateSelected),) === 1

}


export function fetchWithTimeout(url, options = {}, timeout = 5000) {
  return new Promise((resolve, reject) => {
    // Create a timeout promise
    const timeoutId = setTimeout(() => {
      reject(new Error("Request timed out")); // Reject with a timeout error
    }, timeout);

    // Start the fetch request
    fetch(url, options)
      .then(response => {
        clearTimeout(timeoutId); // Clear timeout if fetch resolves
        resolve(response); // Resolve fetch response
      })
      .catch(error => {
        clearTimeout(timeoutId); // Clear timeout if fetch errors
        reject(error); // Reject the error from fetch
      });
  });
}
